.desktop {
    &__background {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 100%;
        overflow-x: hidden;
        align-content: flex-start;
        padding: 1rem 0;
        z-index: 0;
    }
}
.desktopIcon {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 90vh;
    align-items: center;
    text-align: center;
    width: 90px;
    min-height: 100px;
    margin-bottom: 1rem;
    &__button {
        width: 90px;
        padding: 0.5rem 1rem;
        background: transparent;
        outline: none;
        border: none;
        color: #fff;
        text-align: center;
    }
    &__image {
        width: 30px;
        height: 30px;
        image-rendering: pixelated;
        image-rendering: -moz-crisp-edges;
        image-rendering: crisp-edges;
        pointer-events: none;
        &.-focused {
            filter: grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(400%) contrast(0.9);
        }
    }
    &__name {
        font-size: larger;
        margin-top: 3px;
        padding: 2px;
        text-align: center;
        pointer-events: none;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        &.-focused {
            outline: 1px dotted #000;
            background: rgb(0, 0, 118);
            color: white;
        }
    }
}

.close-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: -1px;
    margin-top: -1px;
    transform: rotateZ(45deg);
    position: relative;
    &:before,
    &:after {
        content: '';
        position: absolute;
    }
    &:before {
        height: 100%;
        width: 3px;
        left: 50%;
        transform: translateX(-50%);
    }
    &:after {
        height: 3px;
        width: 100%;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
    }
}