.projectsWindow {
    &__body {
        height: 60vh;
        > h1 {
            font-size: 30px;
            padding-left: 1rem;
        }
    }
    &__bodyInner {
        padding: 1rem;
        padding-left: 1rem;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
        
        .projectTitle {
            line-height: 2;
            font-size: 20px;
            text-decoration: none;
            font-weight: bold;
        }
        > img {
            display: block;
            padding: 1rem;
            margin: auto;
            width: 75%;
        }
        > h3 {
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 859px) {
    .projectsWindow {
        width: calc(90vw - 2rem);
        height: 70vh;
    }
}