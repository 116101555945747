.windowFrame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__inner.windowFrame__inner {
        width: 100%;
        height: 100%;
        min-height: 200px;
    }
    &__content.windowFrame__content {
        flex: 1;
        padding: 0.25rem;
    }
    &__contentInner {
        position: relative;
    }

    &.-about {
        max-height: 80vh;
        width: 60vw;
    }
    &.-engineering {
        max-height: 80vh;
        width: 70vw;
    }
    &.-projects {
        max-height: 80vh;
        width: 60vw;
    }
    &.-resume {
        max-height: 90vh;
    }
}

.windowHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
}
.windowHeader.-inactive {
    background: #999 !important;
}
.windowHeader__title {
    padding-left: 1.75rem;
    &__title {
        > img {
            width: 20px;
            height: 20px;
        }
    }
}
.windowButton__icon.-close {
    font-weight: bold;
    transform: translateY(-2px);
    pointer-events: none;
}

// determines window size 
.content {
    &.-about {
        height: 100%;
    }
    &.-engineering {
        height: 100%;
    }
    &.-projects {
        height: 100%;
    }
    &.-resume {
        height: 75vh;
    }
}

.icon {
    position: absolute;
    margin-top: 6px;
    margin-left: 2px;
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    image-rendering: crisp-edges;
    pointer-events: none;
}

.close {
    padding-right: 2px;
    height: 13px;
}
.close:before, .close:after {
    position: absolute;
    content: ' ';
    height: 13px;
    width: 2px;
    background-color: rgb(0, 0, 0);
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}

.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
}

.scrollable::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.scrollable::-webkit-scrollbar-track {
    border-left: 1px solid #ced0cf;
    background-color: #999;
    background-image: linear-gradient(
        45deg,
        #ced0cf 25%,
        transparent 25%,
        transparent 75%,
        #ced0cf 75%,
        #ced0cf
        ),
        linear-gradient(
        45deg,
        #ced0cf 25%,
        transparent 25%,
        transparent 75%,
        #ced0cf 75%,
        #ced0cf
    );
    background-size: 3px 3px;
    background-position: 0 0, 1.5px 1.5px;
}
.scrollable::-webkit-scrollbar-track:active {
    background-color: #6c6c6c;
}

.scrollable::-webkit-scrollbar-thumb {
    border-top: 1px solid #ced0cf;
    border-left: 1px solid #ced0cf;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    box-shadow: inset 1px 1px 0 0 #fff, inset -1px -1px 0 0 #6c6c6c;
    width: 16px;
    height: 36px;
    background-color: #ced0cf;
    z-index: 1;
}

.scrollable::-webkit-scrollbar-corner {
    background-color: #ced0cf;
}

.scrollable::-webkit-scrollbar-button,
.scrollable::-webkit-scrollbar-button {
    border-top: 1px solid #ced0cf;
    border-left: 1px solid #ced0cf;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    box-shadow: inset 1px 1px 0 0 #fff, inset -1px -1px 0 0 #6c6c6c;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #ced0cf;
    image-rendering: pixelated;
    background-repeat: no-repeat;
    background-position: center center;
}
.scrollable::-webkit-scrollbar-button:active,
.scrollable::-webkit-scrollbar-button:active {
    background-position: 2px 2px;
}
.scrollable::-webkit-scrollbar-button:horizontal:decrement,
.scrollable::-webkit-scrollbar-button:horizontal:decrement {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAAHklEQVQY02NgoBT8xyX8H5fwf1zCpOjAYwceV1EEAAO2D/HsQ4vsAAAAAElFTkSuQmCC");
}
.scrollable::-webkit-scrollbar-button:horizontal:increment,
.scrollable::-webkit-scrollbar-button:horizontal:increment {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAAHUlEQVQY02NgIB/8xy3xH7fEf9wS/0nUQZqrKAYAK44P8ZRmzLQAAAAASUVORK5CYII=");
}
.scrollable::-webkit-scrollbar-button:vertical:decrement,
.scrollable::-webkit-scrollbar-button:vertical:decrement {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGklEQVR4AWMYxuA/SYphmETFhDX9x4mHGQAAcL4P8dQiMq8AAAAASUVORK5CYII=");
}
.scrollable::-webkit-scrollbar-button:vertical:increment,
.scrollable::-webkit-scrollbar-button:vertical:increment {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAQAAAD8fJRsAAAAF0lEQVQY02NgoBf4jwJxSOHQhcNAOgMAWWAP8Rv2U3UAAAAASUVORK5CYII=");
}
.scrollable::-webkit-scrollbar-button:horizontal:increment:start,
.scrollable::-webkit-scrollbar-button:horizontal:increment:start {
    display: none;
}
.scrollable::-webkit-scrollbar-button:horizontal:decrement:end,
.scrollable::-webkit-scrollbar-button:horizontal:decrement:end {
    display: none;
}
.scrollable::-webkit-scrollbar-button:vertical:increment:start,
.scrollable::-webkit-scrollbar-button:vertical:increment:start {
    display: none;
}
.scrollable::-webkit-scrollbar-button:vertical:decrement:end,
.scrollable::-webkit-scrollbar-button:vertical:decrement:end {
    display: none;
}
.scrollable::-webkit-scrollbar-button:active,
.scrollable::-webkit-scrollbar-button:active {
    border: 1px solid #6c6c6c;
    box-shadow: none;
}