.resumeWindow {
    height: 70vh;
}

.react-pdf__Page__textContent {
    display: none;
}

.react-pdf__Page__annotations {
    display: none;
}

.react-pdf__Document{
    max-height: 70vh;
}