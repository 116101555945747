.engineeringWindow {
    padding: 1rem 0;

    &__details {
        height: 100%;
    }

    &__item {
        display: flex;
        margin-top: 2rem;
        width: 80%;
        margin-right: auto;
        margin-left: auto;
        > img {
            width: 35%;
            max-height: 100%;
            height: auto;
            object-fit: contain;
            margin-right: 20px;
        }
        
    }
}

.react-pdf__Page__textContent {
    display: none;
}

.react-pdf__Page__annotations {
    display: none;
}

.react-pdf__Page__canvas {
    height: 40vh;
}

.react-pdf__Document{
    max-height: 30vh;
}

@media screen and (max-width: 1300px) {
    .engineeringWindow {
        &__item {
            flex-direction: column;
            align-items: center;
            width: 90%;

            > p {
                margin-top: 1rem;
            }
            
            > img {
                width: 75%;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }
    }
}

@media screen and (max-width: 859px) {
    .engineeringWindow {
        width: calc(90vw - 2rem);
    }
}